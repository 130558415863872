import AVOTrack from '@lib/avo'

import Features from '@/api/Features'
import { setDataDogUser } from '@/bootstrap/monitor/datadog-poc/datadog'
import monitor from '@/bootstrap/monitor/monitor'
import { PROFILE_CHANGED } from '@/constants/events'
import SSOCookieService from '@/core/api/utils/ssoCookie.service'
import EventBus from '@/core/ui/libs/eventBus'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { initialiseUnleash } from '@/core/ui/plugins/featureToggle/providers/unleash'
import { getActualToggles } from '@/core/ui/plugins/featureToggle/utils/getActualToggles'
import { ROUTE_SELECT_USER } from '@/features/auth/ui/constants/routes.constants'
import { getApiLogoutPath, getPathMatched, getRedirectPath, goToProviderPath } from '@/router/utils/helpers'
import { SET_CULTURE, SET_FEATURES } from '@/store/mutation-types'
import Storage from '@/store/storage'
import * as STORAGE from '@/store/storage-types'
import { getQueryParamsFromLocationSearch, removeQueryParams } from '@/utils/url'

import AccountService from '../../domain/account.service'
import {
    GOOGLE_ANALYTICS_LINKER_QUERY_PARAM,
    LOGIN_ID_QUERY_PARAM,
    SSO_LOGIN_STATE
} from '../../domain/constants/auth.constants'
import { isAuthenticated } from '../../domain/utils/token.utils'
import { AuthActionTypes, AuthGetterTypes } from '../store/types'

const FeaturesApi = Features()

const onError = (error, next) => {
    return next({
        name: 'error',
        params: {
            error
        }
    })
}

// TODO: validate redirection also (https://docplanner.atlassian.net/browse/WSTB-160)
const getMigrationRedirectUrl = to => {
    let url = null

    if (to.name === 'migration' && Object.prototype.hasOwnProperty.call(to.query, 'redirectUrl')) {
        url = to.query.redirectUrl
    } else if (getPathMatched(to) === 'onboarding') {
        url = getRedirectPath(to)
    }

    return url ? { redirectUrl: url } : null
}

export default async function auth({ to, next, store }) {
    const redirectTo = getRedirectPath(to)
    const queryParams = getQueryParamsFromLocationSearch()
    const isUserAuthenticated = isAuthenticated()

    const { state, code } = queryParams

    if (code && state === SSO_LOGIN_STATE) {
        let url
        try {
            url = new URL(redirectTo, window.location.href)
            url.searchParams.delete('code')
            url.searchParams.delete('state')
        } catch (error) {
            monitor.sendException(error)
            url = new URL('/', window.location.href)
        }

        try {
            await store.dispatch(AuthActionTypes.AuthWebToken, code)
            window.location.href = url.href
            return
        } catch (error) {
            return onError(error, next)
        }
    }

    try {
        SSOCookieService.initialize()
    } catch (e) {
        e.message = `Error loading ssoCookie module: ${e.message}`
        monitor.sendException(e)
    }

    if (!isUserAuthenticated) {
        Storage.set(STORAGE.REDIRECT_URL, location.href)

        if (ensureToggleIsEnabled('UseSSOTokenOnSaaS', false)) {
            goToProviderPath()
            return
        }

        try {
            await SSOCookieService.getNewToken()
        } catch (error) {
            monitor.sendException(error)

            store.dispatch(AuthActionTypes.AuthLogout)
            Storage.set(STORAGE.REDIRECT_URL, location.href)
            window.location.href = getApiLogoutPath()

            return false
        }
    }

    if (store.getters[AuthGetterTypes.IsLoggedIn]) {
        return next()
    }

    const hasAccountUsers = store.getters[AuthGetterTypes.GetAccountUsers].length

    if (!hasAccountUsers) {
        try {
            await store.dispatch(AuthActionTypes.FetchUsersAccountUsers)

            if (store.getters[AuthGetterTypes.GetAccountUsers].length === 0) {
                await AccountService.create()

                return next({
                    path: 'migration',
                    query: getMigrationRedirectUrl(to)
                })
            }
        } catch (error) {
            return onError(error, next)
        }
    }

    const marketplaceDoctorId = queryParams[LOGIN_ID_QUERY_PARAM]
    if (marketplaceDoctorId) {
        try {
            const accountUser = store.getters[AuthGetterTypes.GetAccountUserById](marketplaceDoctorId)
            if (!accountUser) {
                throw new Error(`No user found for ${marketplaceDoctorId}`)
            }
            await store.dispatch(AuthActionTypes.AuthLogin, {
                userId: accountUser.id
            })
            removeQueryParams([LOGIN_ID_QUERY_PARAM, GOOGLE_ANALYTICS_LINKER_QUERY_PARAM])
        } catch (error) {
            return onError(error, next)
        }
    } else {
        const currentUserSelected = Storage.get(STORAGE.USER_ID)
        if (!currentUserSelected) {
            if (store.getters[AuthGetterTypes.IsMultiUser]) {
                if (to.name !== ROUTE_SELECT_USER) {
                    return next({
                        name: ROUTE_SELECT_USER,
                        query: {
                            redirectUrl: redirectTo
                        }
                    })
                }

                return next()
            }
            try {
                await store.dispatch(AuthActionTypes.AuthLogin, {
                    userId: store.getters[AuthGetterTypes.GetDefaultAccountUser].id
                })
            } catch (error) {
                return onError(error, next)
            }
        }
    }

    try {
        await initialiseUnleash({
            toggles: getActualToggles()
        })
    } catch (e) {
        monitor.sendException(e)
    }

    try {
        await store.dispatch(AuthActionTypes.FetchProfile)

        Storage.set(STORAGE.LAST_SUCCESSFUL_LOGIN_DATE_TIME, new Date().toISOString())

        const authUser = store.getters[AuthGetterTypes.GetAuthUser]
        const countryCode = authUser.countryCode.toLowerCase()

        store.commit(SET_CULTURE, authUser.culture)

        monitor.setUserId(authUser.id)
        monitor.setTags(store.getters[AuthGetterTypes.GetUserInfoForSentryTags])

        setDataDogUser({
            id: authUser.id,
            ...store.getters[AuthGetterTypes.GetUserInfoForSentryTags]
        })

        AVOTrack.generalUserLogin({
            userId_: `${countryCode}-${authUser.docplannerUserId}`
        })

        AVOTrack.generalSetUserProperties({
            userId_: authUser.docplannerUserId,
            mkplDoctorId: authUser.docplannerDoctorId,
            mkplFacilityId: authUser.docplannerDoctorId,
            mkplUserId: authUser.docplannerUserId,
            pmsUserId: undefined,
            saasDoctorId: authUser.id,
            saasFacilityId: authUser.medicalCenterId,
            saasUserId: authUser.id,
            sourceUserId: authUser.docplannerUserId,
            userType: authUser.userType,
            countryCode,
            isUserIntegrated: undefined
        })

        if (!authUser.isMigrated) {
            return next({
                path: 'migration',
                query: getMigrationRedirectUrl(to)
            })
        }

        if (authUser.isPartiallyMigrated) {
            return next({
                path: 'completemigration',
                query: getMigrationRedirectUrl(to)
            })
        }

        // DOUBT: could it be sent always after store.dispatch(AuthActionTypes.FetchProfile)?
        // Now skipped if migration redirection. Ask plugin-manager developers.
        EventBus.emit(PROFILE_CHANGED, authUser)
    } catch (error) {
        return onError(error, next)
    }

    const navigationAndFeatures = await Promise.allSettled([
        store.dispatch(AuthActionTypes.FetchSaasNavigation),
        FeaturesApi.fetch()
    ])

    const navigationAndFeaturesError = navigationAndFeatures.find(result => result.status === 'rejected')

    if (navigationAndFeaturesError) {
        return onError(navigationAndFeaturesError.reason, next)
    }

    store.commit(SET_FEATURES, navigationAndFeatures[1].value.data)

    return next(redirectTo)
}
